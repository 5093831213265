import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import { RESET_VIEWS } from '../genericActions';
import {
    CANCEL_BLIND_MAINTENANCE,
    CANCEL_BLIND_MAINTENANCE_FAILURE,
    CANCEL_BLIND_MAINTENANCE_SUCCESS,
    CANCEL_INSTALLATION_VERIFICATION,
    CANCEL_INSTALLATION_VERIFICATION_FAILURE,
    CANCEL_INSTALLATION_VERIFICATION_SUCCESS,
    CREATE_MAINTENANCE_SCHEDULE,
    CREATE_MAINTENANCE_SCHEDULE_FAILURE,
    CREATE_MAINTENANCE_SCHEDULE_SUCCESS,
    DELETE_MAINTENANCE_SCHEDULE,
    DELETE_MAINTENANCE_SCHEDULE_FAILURE,
    DELETE_MAINTENANCE_SCHEDULE_SUCCESS,
    LOAD_APPLIANCE,
    LOAD_APPLIANCE_FAILURE,
    LOAD_APPLIANCE_SUCCESS,
    LOAD_LOCATION_WITH_APPLIANCES,
    LOAD_LOCATION_WITH_APPLIANCES_SUCCESS,
    REQUIRE_CLIENT_INSTALLATION,
    REQUIRE_CLIENT_INSTALLATION_FAILURE,
    REQUIRE_CLIENT_INSTALLATION_SUCCESS,
    RESET_ACTION_ERRORS,
    START_BLIND_MAINTENANCE,
    START_BLIND_MAINTENANCE_FAILURE,
    START_BLIND_MAINTENANCE_SUCCESS,
    START_INSTALLATION_VERIFICATION,
    START_INSTALLATION_VERIFICATION_FAILURE,
    START_INSTALLATION_VERIFICATION_SUCCESS,
    TOGGLE_APPLIANCE,
    TOGGLE_APPLIANCE_FAILURE,
    TOGGLE_APPLIANCE_SUCCESS,
    UPDATE_BLIND_MAINTENANCE,
    UPDATE_BLIND_MAINTENANCE_FAILURE,
    UPDATE_BLIND_MAINTENANCE_SUCCESS,
    UPDATE_MAINTENANCE_SCHEDULE,
    UPDATE_MAINTENANCE_SCHEDULE_FAILURE,
    UPDATE_MAINTENANCE_SCHEDULE_SUCCESS,
} from './manageApplianceActions';
import { ReactText } from 'react';
import {
    ApplianceConnectivityWarning,
    ApplianceWithIncidentType,
    LaundryRoomWithApplianceStates,
} from '../manage-laundry-room/manageLaundryRoomUtils';
import { LocationMetadata } from '../../forms/manage-appliance/LaundryRoomAnchorCard';

export enum ApplianceModelGroup {
    W_WWC_2017 = 'W_WWC_2017',
    W_WWC_IDOS_2017 = 'W_WWC_IDOS_2017',
    W_WWC_2023 = 'W_WWC_2023',
    W_WWC_IDOS_2023 = 'W_WWC_IDOS_2023',
    W_OTHER_MANUFACTURER = 'W_OTHER_MANUFACTURER',
    D_WWC_2017 = 'D_WWC_2017',
    D_WWC_2023 = 'D_WWC_2023',
    D_OTHER_MANUFACTURER = 'D_OTHER_MANUFACTURER',
}

export interface ApplianceDto extends ApplianceWithIncidentType {
    appliance_id: number;
    appliance_type: string;
    is_enabled: boolean;
    enabled_activity_time: number;
    number_of_enabled_appliances_of_type_in_laundry_room: number;
    short_name: string;
    location_id: number;
    note: string;
    show_critical_note: boolean;
    critical_note?: string;
    deactivation_history: ApplianceDeactivationEntry[];
    operational_state: string;
    state: string;
    reservation_id: number | null;
    connectivity_state: string;
    connectivity_warning: ApplianceConnectivityWarning;
    control_type: string;
    model_group: ApplianceModelGroup;
    vib: string;
    product_code: string;
    manufacturing_batch: string;
    available_actions: ActionType[];
    installation_verification_status: string | null;
    installation_verification_status_update_time: number | null;
    blind_maintenance_status: string | null;
    blind_maintenance_start_time: number | null;
    blind_maintenance_end_time: number | null;
    blind_maintenance_target_is_enabled: boolean | null;
    maintenance_schedules: ApplianceMaintenanceScheduleDto[];
    appliance_times: {
        lint_filter_replacement?: number;
    };
    lint_filter_replacement?: boolean;
    box_type: BoxType;
    switch_firmware: string | null;
    client_installation_status: string | null;
    client_installation_status_update_time: number | null;
}

export interface ApplianceMaintenanceScheduleDto {
    id?: number;
    appliance_id: number;
    next_start: number;
    duration: number;
    repeat_type: RepeatType;
    repeat_multiplier: number | null;
    monthly_minimum_day: number | null;
    enable_appliance_afterwards: EnableApplianceAfterwards;
    lint_filter_replacement: boolean | null;
    comment: string | null;
}

export enum RepeatType {
    NONE = 'NONE',
    WEEKLY = 'WEEKLY',
    MONTHLY_SAME_WEEKDAY = 'MONTHLY_SAME_WEEKDAY',
}

export enum EnableApplianceAfterwards {
    YES = 'YES',
    NO = 'NO',
    RESTORE_PREVIOUS_STATE = 'RESTORE_PREVIOUS_STATE',
}

export interface BasicApplianceInformation extends ApplianceWithIncidentType {
    id: number;
    short_name: string;
    enabled: boolean;
}

export interface BasicLocationInformation
    extends LaundryRoomWithApplianceStates {
    id: number;
    name: string;
    active_note: string;
    address: AddressDto;
    needs_monitoring: boolean;
    location_meta_data_dto: LocationMetadata;
    visibility_distance: number;
    feedback_question_id?: number;
    feedback_question_response_count?: number;
    desired_feedback_count?: number;
    pre_payment_required?: boolean;
    supports_scan2_wash?: boolean;
}

export interface AddressDto {
    street: string;
    house_number: string;
    postal_code: string;
    city: string;
}

export interface LocationWithAppliancesDto {
    appliances: BasicApplianceInformation[];
    location_information: BasicLocationInformation;
}

export interface ApplianceDeactivationEntry {
    target_state: string;
    event_source: string;
    comment: string;
    ctime: number;
}

export enum BoxType {
    CLIENT = 'CLIENT',
    HUB = 'HUB',
    HUB_USED_AS_CLIENT = 'HUB_USED_AS_CLIENT',
}

export enum ActionType {
    TOGGLE = 'TOGGLE',
    START_INSTALLATION_VERIFICATION = 'START_INSTALLATION_VERIFICATION',
    CANCEL_INSTALLATION_VERIFICATION = 'CANCEL_INSTALLATION_VERIFICATION',
    CREATE_MAINTENANCE_SCHEDULE = 'CREATE_MAINTENANCE_SCHEDULE',
    UPDATE_MAINTENANCE_SCHEDULE = 'UPDATE_MAINTENANCE_SCHEDULE',
    DELETE_MAINTENANCE_SCHEDULE = 'DELETE_MAINTENANCE_SCHEDULE',
    START_BLIND_MAINTENANCE = 'START_BLIND_MAINTENANCE',
    CANCEL_BLIND_MAINTENANCE = 'CANCEL_BLIND_MAINTENANCE',
    UPDATE_BLIND_MAINTENANCE = 'UPDATE_BLIND_MAINTENANCE',
    REQUIRE_CLIENT_INSTALLATION = 'REQUIRE_CLIENT_INSTALLATION',
}

export interface ApplianceAction {
    applianceId: ReactText;
    actionType: ActionType;
}

export interface ManageApplianceStore {
    loading: boolean;
    loadingLocationWithAppliances: boolean;
    appliance: ApplianceDto | null;
    locationWithAppliances?: LocationWithAppliancesDto;
    loadingErrors: WeWashApiErrorTag[];
    actionErrors: WeWashApiErrorTag[];
    latestSuccessAction: ApplianceAction | null;
}

const initialStore: ManageApplianceStore = {
    loading: false,
    loadingLocationWithAppliances: false,
    appliance: null,
    latestSuccessAction: null,
    loadingErrors: [],
    actionErrors: [],
};

export const manageApplianceReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [RESET_ACTION_ERRORS]: (state: ManageApplianceStore) => {
        state.actionErrors = [];
    },
    [LOAD_APPLIANCE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.loadingErrors = [];
        state.actionErrors = [];
    },
    [LOAD_APPLIANCE_SUCCESS]: (state: ManageApplianceStore, action) => {
        state.loading = false;
        state.loadingErrors = [];
        state.appliance = action.payload.data;
    },
    [LOAD_APPLIANCE_FAILURE]: (state: ManageApplianceStore, action) => {
        state.loading = false;
        state.loadingErrors = action.payload.errors;
        state.loadingLocationWithAppliances = false;
        state.appliance = null;
    },
    [LOAD_LOCATION_WITH_APPLIANCES]: (state: ManageApplianceStore, action) => {
        state.loadingLocationWithAppliances = true;
        state.loadingErrors = [];
    },
    [LOAD_LOCATION_WITH_APPLIANCES_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loadingLocationWithAppliances = false;
        state.loadingErrors = [];
        state.locationWithAppliances = action.payload.locationWithAppliances;
    },
    [TOGGLE_APPLIANCE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [TOGGLE_APPLIANCE_SUCCESS]: (state: ManageApplianceStore, action) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.TOGGLE,
        };
    },
    [TOGGLE_APPLIANCE_FAILURE]: (state: ManageApplianceStore, action) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [START_INSTALLATION_VERIFICATION]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [START_INSTALLATION_VERIFICATION_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.START_INSTALLATION_VERIFICATION,
        };
    },
    [START_INSTALLATION_VERIFICATION_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [CANCEL_INSTALLATION_VERIFICATION]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [CANCEL_INSTALLATION_VERIFICATION_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.CANCEL_INSTALLATION_VERIFICATION,
        };
    },
    [CANCEL_INSTALLATION_VERIFICATION_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [CREATE_MAINTENANCE_SCHEDULE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [CREATE_MAINTENANCE_SCHEDULE_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.CREATE_MAINTENANCE_SCHEDULE,
        };
    },
    [CREATE_MAINTENANCE_SCHEDULE_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [UPDATE_MAINTENANCE_SCHEDULE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [UPDATE_MAINTENANCE_SCHEDULE_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.UPDATE_MAINTENANCE_SCHEDULE,
        };
    },
    [UPDATE_MAINTENANCE_SCHEDULE_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [DELETE_MAINTENANCE_SCHEDULE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [DELETE_MAINTENANCE_SCHEDULE_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.DELETE_MAINTENANCE_SCHEDULE,
        };
    },
    [DELETE_MAINTENANCE_SCHEDULE_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [START_BLIND_MAINTENANCE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [START_BLIND_MAINTENANCE_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.START_BLIND_MAINTENANCE,
        };
    },
    [START_BLIND_MAINTENANCE_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [CANCEL_BLIND_MAINTENANCE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [CANCEL_BLIND_MAINTENANCE_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.CANCEL_BLIND_MAINTENANCE,
        };
    },
    [CANCEL_BLIND_MAINTENANCE_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [UPDATE_BLIND_MAINTENANCE]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [UPDATE_BLIND_MAINTENANCE_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.UPDATE_BLIND_MAINTENANCE,
        };
    },
    [UPDATE_BLIND_MAINTENANCE_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
    [REQUIRE_CLIENT_INSTALLATION]: (state: ManageApplianceStore) => {
        state.loading = true;
        state.actionErrors = [];
        state.latestSuccessAction = null;
    },
    [REQUIRE_CLIENT_INSTALLATION_SUCCESS]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = [];
        state.latestSuccessAction = {
            applianceId: action.payload.applianceId,
            actionType: ActionType.REQUIRE_CLIENT_INSTALLATION,
        };
    },
    [REQUIRE_CLIENT_INSTALLATION_FAILURE]: (
        state: ManageApplianceStore,
        action
    ) => {
        state.loading = false;
        state.actionErrors = action.payload.errors;
        state.latestSuccessAction = null;
    },
});
